import React, { useState, useCallback } from "react";
import {
  AppBar,
  Container,
  Toolbar,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import styles from "./styles";

import DehazeIcon from "@material-ui/icons/Dehaze";
import CloseIcon from "@material-ui/icons/Close";

import { DrawerContent, Logo } from "./elements";

const Header = () => {
  const classes = styles();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const menuButton = (
    <div
      onClick={toggleExpanded}
      color="primary"
      className={classes.active}
      aria-label="menu"
    >
            <Typography
        className={classes.menuText}>
          Services
        </Typography>

      {/* {isExpanded ? (
        <CloseIcon className={classes.icon} />
      ) : (
        <Typography
        className={classes.menuText}>
          Services
        </Typography>
      )
      } */}
    </div>
  );
  return (
    <AppBar position="relative" elevation={0} className={classes.AppBar}>
      <Container>
        <Toolbar component="nav" disableGutters className={classes.Toolbar}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Logo />
            </Grid>

            <Grid item>{menuButton}</Grid>
          </Grid>
        </Toolbar>
        {isExpanded && <DrawerContent toggleExpanded={toggleExpanded} />}
      </Container>
    </AppBar>
  );
};

export default Header;
