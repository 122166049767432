import React, { useContext } from "react";
import { Context } from "../../../Context";
import MobileRow from "./MobileRow";
import styles from "./styles";
import {
  Typography,
  Hidden,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Grid,
} from "@material-ui/core";
import { navigate } from "gatsby";

export const DrawerContent = ({ toggleExpanded }) => {
  const classes = styles();
  const { listData } = useContext(Context);

  const rows = listData.map(({ data }) => (
    <TableRow key={data.Name}>
      <TableCell className={classes.tableCell}>
        <Typography
          onClick={() => {
            navigate(`/${data.slug}`);
            toggleExpanded();
          }}
          className={`${classes.label} ${classes.active}`}
          variant="body1"
        >
          {data.Name}
        </Typography>
      </TableCell>
    </TableRow>
  ));
  const mobileRows = listData.map(({ data }) => (
    <MobileRow data={data} key={data.Name} />
  ));
  return (
    <>
      <Hidden smDown>
        <TableContainer className={classes.table}>
          <Table aria-label="caption table">
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Hidden>

      <Hidden mdUp>
        <>
          <Grid item> </Grid>
          {mobileRows}{" "}
        </>
      </Hidden>
    </>
  );
};
export default DrawerContent;
