import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import React from "react";
import { theme } from "../Theme";
import { ContextProvider } from "src/components/Context";

export const ProviderWrapper = ({ children }) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <ThemeProvider theme={theme}>
        <CssBaseline>
          <ContextProvider>{children}</ContextProvider>
        </CssBaseline>
      </ThemeProvider>
    </>
  );
};
