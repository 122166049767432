import React from "react";
import { graphql, useStaticQuery } from "gatsby";
const defaultState = {
  listData: [],
};
const Context = React.createContext(defaultState);
function ContextProvider({ children }) {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allAirtablePages(sort: { order: DESC, fields: id }) {
          nodes {
            data {
              Name
              slug
            }
          }
        }
      }
    `
  );

  const listData = data.allAirtablePages.nodes;
  console.log(listData);
  return (
    <Context.Provider
      value={{
        listData,
      }}
    >
      {children}
    </Context.Provider>
  );
}
export { Context, ContextProvider };
