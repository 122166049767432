import React from "react";

import Header from "./Header";
import Footer from "./Footer";
import { Container, makeStyles, Box } from "@material-ui/core";

const Layout = (props) => {
  const { children } = props;
  const styles = makeStyles((theme) => ({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      margin: "0 12%"
    },
    content: {
      flex: "1 0 auto",
    },
  }));
  const classes = styles();

  return (
    <Box className={classes.wrapper}>
      <Header />

      <Box className={classes.content}>
        <Container> {children} </Container>
      </Box>

      <Footer />
    </Box>
  );
};
export default Layout;
