import React from "react";
import styles from "./styles";
import { Box, Container, Typography, Grid } from "@material-ui/core";
import { navigate } from "gatsby";

export const Footer = () => {
  const classes = styles();
  return (
    <Box className={classes.footer}>
      <Container>
        <Box className={classes.content}>
          <Grid container justify="space-between">
            <Grid item>
              <Box display="inline-block" mr={2}>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.navLink}
                  onClick={() => navigate("/contacts")}
                >
                  Contacts
                </Typography>
              </Box>
              <Box display="inline-block" mr={2}>

              <Typography
                component="span"
                variant="body2"
                className={classes.navLink}
                onClick={() => navigate("/about")}
              >
                About
              </Typography>
              </Box>
              <Box display="inline-block" mr={2}>

              <Typography
                component="span"
                variant="body2"
                className={classes.navLink}
                onClick={() => navigate("https://showroom.dearprogress.com")}
              >
                Enter Showroom
              </Typography>
              </Box>              

            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.caption}>Dear Progress © 2018—{new Date().getFullYear()}
</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
export default Footer;
