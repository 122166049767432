import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  table: {
    position: "relative",
    height: "100%",
    width: "100%",
    background: theme.palette.background.default,
    [theme.breakpoints.up("sm")]: {
      width: "63% !important",
    },
  },
  headerCell: { borderBottom: "1px solid #000000" },
  tableCell: {
    borderBottom: "none !important",
    paddingLeft: "0"
  },
  label: {
    textDecoration: "none",
    textTransform: "uppercase"
  },
  greyText: {
    color: "#6C6C6C",
    marginLeft: "0.5rem",
  },
  active: {
    cursor: "pointer",
  },
}));
export default styles;
