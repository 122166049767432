import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    background: {
      default: "#FFFFFF",
    },
    text: {
      default: "#000000",
      secondary: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: ["Arial", "Roboto", "sans-serif"].join(","),
    h1: {
      fontFamily: ["Helvetica", "Roboto", "sans-serif"].join(","),
      fontSize: 24,
      margin: "3.125rem 0 2.625rem 0",
      fontWeight: "bold",
    },
    h2: {
      fontVariant: "small-caps",
      textTransform: "uppercase",
      fontWeight: "100",
    },
    a: {
      textDecoration: 'none',
    },
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 14,
    },
    footernav: {
      textTransform: 'uppercase',
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
    },
  },
});

theme.overrides = {
  a: {
    textDecoration: 'none',
  },
  MuiLink: {
    root: {
      textDecoration: 'none',
    }
  },
  MuiSelect: {
    select: {
      borderRadius: 4,

      width: "100%",
      minWidth: "5.625rem",
      paddingRight: "3.125rem !important",
      "&:focus": {
        borderRadius: 4,
      },
    },
    icon: { marginRight: "1.125rem" },
  },
  MuiInputBase: {
    root: {},
    input: {
      borderRadius: 4,

      border: "1px solid #000000",
      padding: "7px 18px 9px 13px",
      "&:focus": {
        borderRadius: 4,
      },
    },
  },
  MuiInputLabel: {
    root: {
      color: theme.palette.text.default,
      marginLeft: "13px",
    },
  },

  MuiButton: {
    root: {
      width: "14.625rem",
      height: "3.375rem",

      border: "1px solid #000000",
      boxSizing: "border-box",
      borderRadius: "0.9375rem",
    },
  },
  MuiMenuItem: {
    root: {
      minHeight: "2.5rem",
      [theme.breakpoints.up("sm")]: {
        minHeight: "2.5rem",
      },
    },
  },
  MuiFormHelperText: {
    root: {
      color: theme.palette.warning.main,
    },
  },
  MuiRadio: {
    root: {
      color: theme.palette.primary.main,
    },
  },
};